/*

  Standard library of methods for making Ajax requests from Webpack/React

  The 'standard' method of making a request does so with no visual side effects

  There are also ways to make a request with a Flash message or do support
  legacy endpoints (powered by execute_and_render_json)

  Instead of a hash of configuration, we use Axios `instances` to explicitly
  signify which type of request we want to make.

  All HTTP methods are supported.

  e.g:
    someAxiosInstance.get(url[, config])
    someAxiosInstance.delete(url[, config])
    someAxiosInstance.head(url[, config])
    someAxiosInstance.options(url[, config])
    someAxiosInstance.post(url[, data[, config]])
    someAxiosInstance.put(url[, data[, config]])
    someAxiosInstance.patch(url[, data[, config]])
*/

// Polyfill es6 promise if it does not exist
// This must happen before we import axios
require('es6-promise/auto');

import documentReady from 'document-ready';
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  AxiosPromise,
} from 'axios';
import Flash, { FlashMessage } from 'legacy/flash';
import tap from 'lodash/tap';
import identity from 'lodash/identity';
import get from 'lodash/get';
import t from 'shared/utils/translation';
import { flashError, flashSuccess } from 'flash/flash_manager';

/*
  Globally add the CSRF token to every request sent via Axios.
  All other configuration should happen on an 'instance' basis, not globally
*/

export const PendingClassName = '.flash-pending';

export type RequestError = AxiosError;
export type Response<T> = AxiosResponse<T>;
export type RequestConfig = AxiosRequestConfig;
export type RequestInstance = AxiosInstance;
export type PendingRequest<T> = AxiosPromise<T>;

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

documentReady(() => {
  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');

  if (csrfTokenElement) {
    axios.defaults.headers.common['X-CSRF-Token'] =
      csrfTokenElement.attributes.getNamedItem('content')?.value;
  }

  axios.defaults.headers.common.Accept = 'application/json';
});

/*
  Set up request logging for development only. Easily see the headers/params
  you are sending up to the server

  On response side, see headers / status code, response (more info than chrome)
*/

let logRequest: (requestConfig: RequestConfig) => RequestConfig = identity;
let logResponse: <T>(res: Response<T>) => Response<T> = identity;

if (__DEV__) {
  /* eslint-disable no-console */
  logRequest = (requestConfig: RequestConfig) => {
    console.groupCollapsed &&
      console.groupCollapsed(`Request to ${requestConfig.url}`);
    console.log(requestConfig);
    console.groupEnd && console.groupEnd();

    return requestConfig;
  };

  logResponse = <T>(res: Response<T>) => {
    if (console.groupCollapsed) {
      if (axios.isCancel(res)) {
        console.groupCollapsed('Canceled Request');
      } else {
        console.groupCollapsed(`Reponse from ${res.config?.url}`);
      }
    }
    console.log(res);
    console.groupEnd && console.groupEnd();

    return res;
  };

  /* eslint-enable no-console */
}

const defaultRejectError = (error: Error) => {
  return Promise.reject(error);
};

type LegacyError = {
  status?: 'error';
};

export const isLegacyError = <T>(res: Response<T>) => {
  const r = res as unknown as Response<LegacyError>;
  return r.status === 200 && r.data.status === 'error';
};

export const rejectLegacyErrors = <T>(res: Response<T>) => {
  if (isLegacyError(res)) {
    return Promise.reject(res);
  } else {
    return res;
  }
};

export const getPendingMessage = (config: RequestConfig): FlashMessage => {
  let message = config.config?.flashMessage;

  if (typeof message === 'function') {
    message = message(config);
  }

  if (message) {
    return message;
  }

  return get(
    {
      get: t('flash.loading'),
      post: t('flash.saving'),
      put: t('flash.saving'),
      delete: t('flash.deleting'),
      patch: t('flash.saving'),
    },
    config.method ?? '',
    t('flash.saving')
  );
};

export const getNoticeMessage = <T>(response: Response<T>) => {
  let message = response.config?.successMessage;

  if (typeof message === 'function') {
    message = message(<RequestConfig>response);
  }

  return message || get(response, 'data.message');
};

export const getCustomErrorMessage = <T>(response: Response<T>) => {
  let message = response.config?.errorMessage;

  if (typeof message === 'function') {
    message = message(<RequestConfig>response);
  }

  if (message) {
    return typeof message === 'string' ? message : message.join(' ');
  }
};

export const getErrorText = (
  data: {
    message?: string;
    errorMessage?: string;
    error?: { message: string };
  } | null
): string => {
  return (
    data?.message ||
    data?.errorMessage ||
    data?.error?.message ||
    t('flash.error')
  );
};

export const showPendingFlashMessage = (
  requestConfig: RequestConfig
): RequestConfig => {
  const message = getPendingMessage(requestConfig);
  Flash.setPending(message);
  return requestConfig;
};

export const removePendingFlashMessage = <T>(res: Response<T>): Response<T> => {
  Flash.remove(PendingClassName);
  return res;
};

const successResponse = (statusCode: number): boolean =>
  statusCode >= 200 && statusCode <= 300;

const isResponseErrorObject = <T>(
  responseOrError: Response<T> | RequestError
): responseOrError is RequestError => {
  const err = <RequestError>responseOrError;
  const status = err?.response?.status;

  return !!status && !successResponse(status);
};

export const showResponseFlashMessages = <T>(res: Response<T>): Response<T> => {
  if (isLegacyError(res)) {
    const message = getCustomErrorMessage(res) || getErrorText(res.data);

    Flash.setError(message);
    flashError(message);
  } else if (isResponseErrorObject(res)) {
    const message =
      getCustomErrorMessage(res) || getErrorText(res?.response?.data);

    Flash.setError(message);
    flashError(message);
  } else {
    const message = getNoticeMessage(res);

    if (message) {
      Flash.setNotice(message);
      flashSuccess(message);
    }
  }

  return res;
};

const rejectBadResponses = <T>(res: Response<T>) => {
  if (isResponseErrorObject(res)) {
    return defaultRejectError(res);
  }

  return res;
};

let incrementRequestCount: (requestConfig: RequestConfig) => RequestConfig =
  identity;
let decrementRequestCount: <T>(response: Response<T>) => Response<T> = identity;

if (!__PROD__) {
  // spec/support/helpers/wait_for_ajax.rb uses this to support the wait_for_ajax capybara helper
  window.axiosPendingRequestCount = 0;

  incrementRequestCount = (requestConfig) => {
    window.axiosPendingRequestCount++;
    return requestConfig;
  };

  decrementRequestCount = (response) => {
    window.axiosPendingRequestCount--;
    return response;
  };
}

const raiseErrorForNullUrl = (req: RequestConfig): RequestConfig => {
  if ('url' in req && req.url === null) {
    throw new Error(
      `Attempting to make a request with a null URL is not supportable.
       The RequestConfig type allows for a null url but shared/utils/request cannot make the request.`
    );
  }

  return req;
};

/*

  Our Axios instances. 4 flavors for now:
  - Standard
  - Standard with flash
  - Legacy (handles execute_and_render_json) with flash
  - Legacy without flash

  The api is the same for all of them.

  axiosInstance.anyValidHttpMethod(url, params)
  .then((res) => {

  })
  .catch((res) => {

  })

*/

const newAxiosInstance = (configFn: (instance: RequestInstance) => void) =>
  tap(axios.create(), configFn);

export const standardRequest = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(rejectBadResponses, identity);
});

export const standardRequestWithFlashNoPending = newAxiosInstance(
  (instance) => {
    instance.interceptors.request.use(logRequest, logRequest);
    instance.interceptors.request.use(incrementRequestCount, identity);
    instance.interceptors.request.use(raiseErrorForNullUrl, identity);

    instance.interceptors.response.use(logResponse, logResponse);
    instance.interceptors.response.use(
      decrementRequestCount,
      decrementRequestCount
    );
    instance.interceptors.response.use(removePendingFlashMessage, identity);
    instance.interceptors.response.use(showResponseFlashMessages, identity);
    instance.interceptors.response.use(rejectBadResponses, identity);
  }
);

export const standardRequestWithFlash = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);
  instance.interceptors.request.use(
    showPendingFlashMessage,
    showPendingFlashMessage
  );

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(removePendingFlashMessage, identity);
  instance.interceptors.response.use(showResponseFlashMessages, identity);
  instance.interceptors.response.use(rejectBadResponses, identity);
});

export const standardRequestWithErrorFlash = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(showResponseFlashMessages, identity);
  instance.interceptors.response.use(rejectBadResponses, identity);
});

export const standardRequestWithPendingFlash = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);
  instance.interceptors.request.use(
    showPendingFlashMessage,
    showPendingFlashMessage
  );

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(removePendingFlashMessage, identity);
  instance.interceptors.response.use(rejectBadResponses, identity);
});

export const legacyRequestWithFlash = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);
  instance.interceptors.request.use(showPendingFlashMessage, identity);

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(removePendingFlashMessage, identity);
  instance.interceptors.response.use(showResponseFlashMessages, identity);

  instance.interceptors.response.use(rejectLegacyErrors, identity);
});

export const legacyRequest = newAxiosInstance((instance) => {
  instance.interceptors.request.use(logRequest, logRequest);
  instance.interceptors.request.use(incrementRequestCount, identity);
  instance.interceptors.request.use(raiseErrorForNullUrl, identity);

  instance.interceptors.response.use(logResponse, logResponse);
  instance.interceptors.response.use(
    decrementRequestCount,
    decrementRequestCount
  );
  instance.interceptors.response.use(rejectLegacyErrors, identity);
});

// Expose standard request on window so it can be tested by spec/system/csrf_token_spec.rb
window.__axiosStandardRequest__ = standardRequest;

export const getCancelTokenSource = () => axios.CancelToken.source();

export const isCancel = axios.isCancel;
